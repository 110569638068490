/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FaLinkedinIn,
  FaBehance,
  FaGithub,
  FaInstagram,
  FaFacebook,
} from 'react-icons/fa';
import getProfileData from '../data/data';

interface ISocialsProps {
  margins?: number[];
}

function Socials({ margins = [] }: ISocialsProps) {
  const profileData = getProfileData();

  const getSocialData = (key: string, icon: any) => {
    const socialLink = profileData.socials.filter((x) => x.name === key);
    if (socialLink.length > 0) {
      return (
        <a
          key={key} // Adding unique key here
          className="social-link mt-2 d-block text-center"
          style={margins.length > 0 ? { margin: margins.map((item) => (`${item}px `)).join(' ') } : undefined}
          href={socialLink[0].url}
        >
          {icon}
        </a>
      );
    }
    return null; // Return null instead of an empty fragment
  };

  return (
    <>
      {getSocialData('Github', <FaGithub />)}
      {getSocialData('LinkedIn', <FaLinkedinIn />)}
      {getSocialData('Facebook', <FaFacebook />)}
      {getSocialData('Instagram', <FaInstagram />)}
      {getSocialData('Behance', <FaBehance />)}
    </>
  );
}

export default Socials;
