import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './layouts/App';

// Create a root element using React 18's createRoot
const root = ReactDOM.createRoot(document.getElementById('root')!);

// Render the App inside the root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
